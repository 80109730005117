<template>
  <div class="">
    <div
      class="flex flex-between align-items-center fs17 pl10 pr10 pt5 pb5"
      style="height: 50px"
      :class="routername == path ? 'boxbgs color-white' : 'color-shallow'"
      @click="runpage"
    >
      <div class="pl15 fs20">{{ title }}</div>
      <div
        v-if="havechildren"
        class="btnd flex flex-center align-items-center mt5 mb5"
      >
        <div
          class="iconfont icon-xuanzeqizhankai"
          @click.stop="toggleExpand"
        ></div>
      </div>
    </div>
    <CollapseTransition>
      <div class="description" v-show="expanded">
        <slot></slot>
      </div>
    </CollapseTransition>
  </div>
</template>

<script>
import CollapseTransition from "@/components/opencard.vue";
export default {
  name: "openbox",
  components: {
    CollapseTransition,
  },
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    toggleExpand() {
      // if (!this.havechildren) {
      //   this.$router.push(this.path);
      //   this.$store.commit("changeleftcard1", false);
      //   return;
      // }

      this.expanded = !this.expanded;
    },
    runpage() {
      // console.log(this.path);

      this.$store.commit("changeleftcard1", false);
      if (this.path === "/pcsbox") {
        window.open("https://www.zoeess.com");
        return;
      }
      this.$router.push(this.path);
    },
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    routername: {
      type: String,
      default: "/",
    },

    havechildren: {
      type: Boolean,
      default: true,
    },
    path: {
      type: String,
      default: "/",
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.btnd {
  width: 32px;
  height: 32px;
  border-radius: 2px;
}
.boxbgs {
  background-image: linear-gradient(to right, #6fb545, #3b904c, #096d53);
}
</style>
