// 封装请求
import axios from "axios";
// import { ElMessage } from "element-plus";
// 请求
const http = axios.create({
  // "http://zoesolar.com/"
  baseURL: "https://zoesolar.com/",
  timeout: 6000,
});
// 请求拦截
http.interceptors.request.use(
  (config) => {
    //请求头设置
    // let token = localStorage.getItem("token") || "";
    // config.headers.Authorization = token;
    return config;
  },
  (err) => {
    console.log("请求失败", err);
  }
);
// 响应拦截
http.interceptors.response.use(
  (arr) => {
    // 对响应码的处理

    return arr;
  },
  (err) => {
    console.log("", err);
  }
);
// 返出
export default http;
