<template>
  <div id="app">
    <div class="app-mines">
      <!-- PC 头部 -->
      <div
        v-if="!isMobile"
        class="fs18 handers-father"
        :class="
          fixedheand
            ? 'fixed fixedheand hendbgcolor animate__animated animate__fast animate__fadeInDown'
            : ' color-white '
        "
      >
        <div class="box1200auto flex flex-between align-items-center">
          <!-- 默认 -->
          <div class="logo" v-if="!fixedheand">
            <img src="@/assets/images/footlogo.png" alt="" />
          </div>
          <!-- 头部浮动起来 -->
          <div class="logo" v-if="fixedheand">
            <img src="@/assets/images/logo.png" alt="" />
          </div>
          <div v-for="(item, index) in hendtitle" :key="index">
            <div
              @click="changpages(item)"
              class="pr20 text-shadow"
              :class="{
                'color-white fw600': routerpath == item.path,
                'color-main1': fixedheand && routerpath == item.path,
                'fixhendtit ': fixedheand,
                'hendtit ': !fixedheand,
              }"
            >
              {{ item.name }}
            </div>
          </div>

          <div class="flex align-items-center">
            <el-tooltip
              effect="dark"
              content="zy@zoesolar.com"
              placement="bottom"
            >
              <div class="iconfont icon-youjian fs30 mr30"></div>
            </el-tooltip>

            <el-tooltip
              effect="dark"
              content="021-5296-5907"
              placement="bottom"
            >
              <div class="iconfont icon-dianhua fs24"></div>
            </el-tooltip>
          </div>
        </div>
      </div>
      <!-- 手机头部 -->
      <div v-else>
        <div class="mobile-heander">
          <div class="logoq pl10">
            <img src="@/assets/images/logo.png" alt="" />
          </div>
          <div>
            <div
              class="fs30 iconfont icon-caidan color-shallow"
              @click="leftcard(true)"
            ></div>
          </div>
        </div>
        <transition name="Fade">
          <div class="leftcard" v-if="leftcard1">
            <div class="flex flex-between align-items-center width-full p10">
              <div class="logoq pl10">
                <img src="@/assets/images/logo.png" alt="" />
              </div>
              <div>
                <div
                  class="fs30 iconfont icon-caidan color-shallow"
                  @click="leftcard(false)"
                ></div>
              </div>
            </div>

            <div class="">
              <!-- 首页 -->
              <openbox
                title="首页"
                :havechildren="false"
                :routername="routerpath"
                path="/newtest"
              ></openbox>
              <!-- 能源产品 -->
              <openbox
                title="卓阳电站"
                :havechildren="true"
                path="/transtation"
                :routername="routerpath"
              >
                <div class="sonsboxtitle color-shallow">
                  <div
                    @click="changpages1('/transtation')"
                    class="pl30 fs17 p15"
                    :class="{ boxbgs: routerpath == '/bb' }"
                  >
                    独山县卓申光伏电站
                  </div>
                  <div
                    @click="changpages1('/transtation')"
                    class="pl30 fs17 p15"
                    :class="{ boxbgs: routerpath == '/qq' }"
                  >
                    关岭县岗乌卓阳农业光伏电站项目
                  </div>
                </div>
              </openbox>
              <!-- 卓阳储能 -->
              <openbox title="卓阳储能" :havechildren="false" path="/pcsbox">
                <!-- <div class="sonsboxtitle color-shallow">
                  <div
                    class="pl30 fs17 p15"
                    :class="{ boxbgs: routerpath == '/ww' }"
                  >
                    分布式储能
                  </div>
                  <div
                    class="pl30 fs17 p15"
                    :class="{ boxbgs: routerpath == '/qq' }"
                  >
                    电站侧储能阵列
                  </div>
                  <div
                    class="pl30 fs17 p15"
                    :class="{ boxbgs: routerpath == '/qq' }"
                  >
                    100KW组串式PCS
                  </div>
                </div> -->
              </openbox>

              <!-- 解决方案 -->
              <openbox title="解决方案" :havechildren="true" path="/programme">
                <div class="sonsboxtitle color-shallow">
                  <div
                    class="pl30 fs17 p15"
                    :class="{ boxbgs: routerpath == '/ww' }"
                  >
                    集中式光伏风力电站
                  </div>
                  <div
                    class="pl30 fs17 p15"
                    :class="{ boxbgs: routerpath == '/qq' }"
                  >
                    零碳园区
                  </div>
                  <div
                    class="pl30 fs17 p15"
                    :class="{ boxbgs: routerpath == '/qq' }"
                  >
                    光储充一体停车位
                  </div>
                  <div
                    class="pl30 fs17 p15"
                    :class="{ boxbgs: routerpath == '/qq' }"
                  >
                    模块化储能
                  </div>
                </div>
              </openbox>

              <!-- 关于我们 -->
              <openbox
                title="关于我们"
                :havechildren="false"
                :routername="routerpath"
                path="/about"
              ></openbox>
              <!-- 新闻 -->
              <openbox
                title="新闻"
                :havechildren="false"
                :routername="routerpath"
                path="/news"
              ></openbox>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <router-view></router-view>
    <!-- PC 底部 -->
    <div class="footbottom color-white" v-if="!isMobile">
      <div class="box1200auto flex">
        <div>
          <div class="twowm footlogo pb20">
            <img src="@/assets/images/footlogo.png" alt="" />
          </div>
          <div class="flex align-items-center">
            <div class="twowm erwmgzh">
              <img src="@/assets/images/erwmgzh.png" alt="" />
            </div>
            <div class="pl20">
              <div class="flex align-items-end pb20">
                <div class="fs36 iconfont icon-gongzhonghao"></div>
                <div class="pl10 pb5">关注我们</div>
              </div>
              <div class="fs15 color-shallow">关注微信公众号</div>
              <div class="fs15 color-shallow">新鲜资讯，尽在掌握</div>
            </div>
          </div>
          <div class="pt10">
            公司地址: 上海市青浦区 华隆路 E通世界华新园 2号楼
          </div>
          <div class="pt10">联系电话: 021-5296-5907</div>
        </div>

        <div class="pt40 pl50 flex">
          <div
            class="pl50 botombox"
            v-for="(item, index) in footbootm"
            :key="index"
          >
            <div class="mb20 fw600 fs18 changheand">{{ item.title }}</div>

            <div
              v-for="(son, ind) in item.children"
              :key="ind"
              class="color-white1 mb20 fs15 hend changheand"
            >
              {{ son.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!isMobile"
      class="pt10 pb10 color-white text-center flex flex-center"
      style="background: #313841"
    >
      <a
        href="https://beian.miit.gov.cn"
        id="beian"
        style="color: #fff"
        target="_blank"
        >沪ICP备13023445号-1</a
      >
      <span class="pl20"></span>
      <img
        class="pr5"
        style="display: inline-block"
        src="@/assets/images/ga.png"
        alt=""
      />
      <a
        href="https://beian.mps.gov.cn/#/query/webSearch?code=31011802005044"
        rel="noreferrer"
        style="color: #fff"
        target=" blank"
      >
        沪公网安备31011802005044</a
      >
    </div>
    <!-- 手机底部 -->
    <div class="footbottom color-white pl15 pr15" v-if="isMobile">
      <div class="twowm footlogo pb20">
        <img src="@/assets/images/footlogo.png" alt="" />
      </div>
      <div class="pl50">
        <div class="flex align-items-center">
          <div class="twowm erwmgzh">
            <img src="@/assets/images/erwmgzh.png" alt="" />
          </div>
          <div class="pl20">
            <div class="flex align-items-end pb20">
              <div class="fs36 iconfont icon-gongzhonghao"></div>
              <div class="pl10 pb5">关注我们</div>
            </div>
            <div class="fs15 color-shallow">关注微信公众号</div>
            <div class="fs15 color-shallow">新鲜资讯，尽在掌握</div>
          </div>
        </div>
        <div class="pt30 color-shallow">
          <div class="pt10">联系电话：021-51965907</div>
          <div class="pt10">邮箱：zy@zoesolar.com</div>
          <div class="pt10">地址：上海市青浦区华新镇华隆路</div>
          <div class="pt10">1777号e通世界华新园2号楼</div>
          <div class="pt10">copyright @ 2022 卓阳能源集团有限公司</div>
          <div class="pt10">沪ICP备13023445号-1</div>

          <!-- <div class="pt10">沪公网安备 13052802000607号</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import openbox from "@/components/openbox.vue";
import { btn_click } from "@/requesturl/user.js";
import { formatterDate } from "@/utils/date.js";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    openbox,
  },
  data() {
    return {
      routerpath: "/",
      scroll: "",
      fixedheand: false,
      footbootm: [
        {
          title: "卓阳电站",
          id: 0,
          children: [
            {
              name: "贵州电站",
            },
            {
              name: "江西电站",
            },
            {
              name: "西藏电站",
            },
          ],
        },
        {
          title: "解决方案",
          id: 0,
          children: [
            {
              name: "集中式光伏电站",
            },
            {
              name: "零碳园区",
            },
            {
              name: "光储充一体停车位",
            },
            {
              name: "模块化储能",
            },
          ],
        },
        // {
        //   title: "储能产品",
        //   id: 0,
        //   children: [
        //     {
        //       name: "Z BOX-C",
        //     },
        //     {
        //       name: "CityBox-H",
        //     },
        //     {
        //       name: "CityBox阵列",
        //     },
        //     {
        //       name: "PCS",
        //     },
        //   ],
        // },

        {
          title: "关于我们",
          id: 0,
          children: [
            {
              name: "企业文化",
            },
            {
              name: "发展历程",
            },

            {
              name: "人才招聘",
            },
          ],
        },
        {
          title: "新闻",
          id: 0,
          children: [],
        },
      ],
      hendtitle: [
        { name: "首页", path: "/newtest" },
        // { name: "首页", path: "/home" },
        { name: "卓阳电站", path: "/transtation" },
        { name: "卓阳储能", path: "/pcsbox" },
        { name: "解决方案", path: "/programme" },
        { name: "关于我们", path: "/about" },
        { name: "新闻", path: "/news" },
      ],
    };
  },
  computed: {
    ...mapGetters(["leftcard1", "isMobile"]),
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    this.$store.commit("changeisMobile", this.isMobiles());
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route(to, from) {
      console.log(from.path, "到", to.path);
      this.routerpath = to.path;
      this.$store.commit("changepaths", to.path);
    },
  },
  methods: {
    leftcard(bln) {
      this.$store.commit("changeleftcard1", bln);
    },
    changpages1(path) {
      this.$store.commit("changeleftcard1", false);
      this.$router.push(path);
    },
    changpages(path) {
      this.user_btn_click("头部标签", path.path, path.name);
      this.$store.commit("changeleftcard1", false);
      if (path.path === "/pcsbox") {
        window.open("https://www.zoeess.com");
        return;
      }

      this.$router.push(path.path);
    },
    user_btn_click(where, url, name) {
      let timestamp = new Date();
      btn_click({
        click_what: where, // a按钮对应标题
        click_url: url, // 按钮去往的页面路径
        begin_url: "app", // 当前页面的地址
        begin_name: "头部标签", // 当前页面的名字
        click_btn_name: name, // 按钮的名字
        click_time: formatterDate(timestamp, "yyyy-MM-dd hh:mm:ss"),
        click_type: this.isMobile ? "手机" : "电脑",
      });
    },
    handleScroll() {
      //或者使用document.querySelector('.class或者#id').scrollTop
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      // if (this.scroll >= 150) {
      //   this.fixedheand = true;
      // }
      // if (this.scroll < 150) {
      //   this.fixedheand = false;
      // }
    },
    isMobiles() {
      var userAgentInfo = navigator.userAgent;
      var mobileAgents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var mobile_flag = false;
      //根据userAgent判断是否是手机
      for (var v = 0; v < mobileAgents.length; v++) {
        if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
          mobile_flag = true;
          break;
        }
      }
      var screen_width = window.screen.width;
      var screen_height = window.screen.height;
      //根据屏幕分辨率判断是否是手机
      if (screen_width > 325 && screen_height < 500) {
        mobile_flag = true;
      }
      return mobile_flag;
    },
  },
  //销毁,否则跳到别的路由还是会出现
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss">
@import "styles/base.scss";
#app {
}

.sonsboxtitle {
  background: #f2f3f5;
}
.footbottom {
  background: #353c46;
  padding: 20px 0 60px;
  .twowm {
    // width: 30px;
    // height: 30px;
  }
  .footlogo {
    width: 141px;
    height: 80px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .erwmgzh {
    width: 110px;
    height: 110px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .botombox {
    width: 177px;
  }
}

.app-mines {
  position: relative;
}
.leftcard {
  width: 100%;
  height: 100vh;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  overflow: hidden;
  // padding: 15px;
}
.hendbgcolor {
  background: #fff;
  color: #333 !important;
}
.mobile-heander {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 100;
  width: 100%;
  padding: 10px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 7%);
  .logoq {
    img {
      width: 100px;
      height: 40px;
    }
  }
}
.logoq {
  img {
    width: 123px;
    height: 50px;
  }
}
.btnd {
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  border-radius: 2px;
}

.fixhendtit {
  width: 120px;
  text-align: center;
  cursor: pointer;
}

.fixhendtit:hover {
  color: #006450;
  font-weight: 600;
}
.hendtit {
  width: 120px;
  text-align: center;
  cursor: pointer;
}

.hendtit:hover {
  color: #fff;
  font-weight: 600;
}
.fixedheand {
  padding: 8px 0px;
  // box-shadow: 0px 1px 5px #006450;

  box-shadow: 0 0 10px rgb(0 0 0 / 7%);
}
.handers-father {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 999;
  width: 100%;
  padding: 10px 0px;

  // max-width: 1440px;
  // background: rgba($color: #fff, $alpha: 0.3);
  .logo {
    width: 123px;
    height: 50px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .logoq {
    img {
      width: 100px;
      height: 40px;
    }
  }
}

.Fade-enter,
.Fade-leave-to {
  transform: translateX(300px);
  opacity: 0;
}
.Fade-enter-to,
.Fade-leave {
  opacity: 1;
}

.Fade-enter-active,
.Fade-leave-active {
  transition: all 0.5s ease;
}
* {
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;

  // font-family: "Lucida Calligraphy", cursive, serif, sans-serif;
}
.box1600auto {
  width: 1600px;
  margin: 0 auto;
}

.box1200auto {
  width: 1200px;
  margin: 0 auto;
}
.boxbgs {
  background-image: linear-gradient(to right, #6fb545, #3b904c, #096d53);
}
</style>
