// 封装页面请求
import { toreq } from "@/common/request.js";

const { request1, request } = toreq;
// 登录 用户管理 权限管理页面的api请求
// console.log(request1,'request1--request1')
// 登录验证接口
export const lookList = (data) =>
  request1({ url: "/api/add_adminList", method: "post", data });

// 获取当前埋点数
export const getnum = (timestamp) =>
  request1({ url: "/api/get_click", method: "get", params: timestamp });

// 新增
export const add_click = (data) =>
  request1({ url: "/api/add_click", method: "post", data });

// 修改 埋点浏览时长
export const change_looktime = (data) =>
  request1({ url: "/api/change_looktime", method: "post", data });

// 修改 埋点浏览时长
export const btn_click = (data) =>
  request1({ url: "/api/btn_click", method: "post", data });

export default {
  lookList,
  add_click,
  change_looktime,
  getnum,
  btn_click,
};
