import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    routerpath: "/",
    leftcard1: false,
    isMobile: false,
  },
  getters: {
    routerpath: (state) => state.routerpath,
    leftcard1: (state) => state.leftcard1,
    isMobile: (state) => state.isMobile,
  },
  mutations: {
    changepaths(state, path) {
      state.routerpath = path;
    },
    changeleftcard1(state, bl) {
      state.leftcard1 = bl;
    },
    changeisMobile(state, bl) {
      state.isMobile = bl;
    },
  },
  actions: {},
});

export default store;
