// 封装请求参数
import http from "./http.js";
import http1 from "./http1.js";
function request({ method = "get", url, data = {}, params = {} }) {
  return http({
    method,
    url,
    data,
    params,
  });
}


function request1({ method = "get", url, data = {}, params = {} }) {
  return http1({
    method,
    url,
    data,
    params,
  });
}

export const toreq = {
  request1:request1,
  request:request
}


