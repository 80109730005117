// 封装请求
import axios from "axios";
// import { ElMessage } from "element-plus";
// 请求
const http1 = axios.create({
  // "http://zoesolar.com/"
  // http://192.168.8.59:3000
  baseURL: "https://ypt.zoesolar.com",
  // baseURL: "http://192.168.8.59:3000",
  // baseURL: "http://fhw.com",
  timeout: 6000,
});
// 请求拦截
http1.interceptors.request.use(
  (config) => {
    //请求头设置
    // let token = localStorage.getItem("token") || "";
    // config.headers.Authorization = token;
    return config;
  },
  (err) => {
    console.log("请求失败", err);
  }
);
// 响应拦截
http1.interceptors.response.use(
  (arr) => {
    // 对响应码的处理

    return arr;
  },
  (err) => {
    console.log("", err);
  }
);
// 返出
export default http1;
