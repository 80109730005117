import Vue from "vue";
import VueRouter from "vue-router";

//1 注入插件
Vue.use(VueRouter);

//2 定义路由
const routes = [
  //添加映射关系
  {
    //默认首页
    path: "/",
    redirect: "/newtest",
    // redirect: "/home",
  },
  {
    path: "/home",
    component: () => import("@/views/home/index"),
    meta: {
      title: "首页",
    },
  },
  {
    path: "/transtation",
    component: () => import("@/views/transtation/index"),
    meta: {
      title: "卓阳电站",
    },
  },
  {
    path: "/transtationdetial/:id",
    component: () => import("@/views/transtationdetial/index"),
    meta: {
      title: "电站详情",
    },
  },

  {
    path: "/pcsbox",
    component: () => import("@/views/pcsbox/index"),
    meta: {
      title: "卓阳储能",
    },
  },
  {
    path: "/pcsboxdetial/:id",
    component: () => import("@/views/pcsboxdetial/index"),
    meta: {
      title: "储能详情",
    },
  },
  {
    path: "/programme",
    component: () => import("@/views/programme/index"),
    meta: {
      title: "解决方案",
    },
  },
  {
    path: "/news",
    component: () => import("@/views/news/index"),
    meta: {
      title: "新闻",
    },
  },
  {
    path: "/newsdetial/:id",
    component: () => import("@/views/newsdetial/index"),
    meta: {
      title: "新闻详情",
    },
  },
  {
    path: "/addpreore",
    component: () => import("@/views/addpreore/index"),
    meta: {
      title: "人才招聘",
    },
  },
  {
    path: "/visit",
    component: () => import("@/views/visit/index"),
    meta: {
      title: "登记信息",
    },
  },
  {
    path: "/newtest",
    component: () => import("@/views/newtest/index"),
    meta: {
      title: "新首页",
    },
  },
  {
    path: "/about",
    meta: {
      title: "关于我们",
    },
    component: () => import("@/views/about/index"),
  },

  {
    path: "*",
    redirect: "/404",
  },
  {
    path: "/404",
    component: () => import("@/views/nopage/index"),
  },
];

//3 创建router实例
const router = new VueRouter({
  routes,
});

//4 导出router实例
export default router;
