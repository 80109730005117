import Vue from "vue";
import App from "./App.vue";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "animate.css";

//导入router
import router from "./router";
import store from "./store";

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  // 兼容IE
  window.scrollTo(0, 0);
  if (to.matched.length === 0) {
    //未匹配到的时候跳转到404页面
    next({ name: "notFindPage" });
  } else {
    next(); //匹配到的时候跳转相应的路由
  }
});

Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
